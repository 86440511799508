import { type TaxType } from "@prisma/client";
import { type Params } from "next/dist/shared/lib/router/utils/route-matcher";
import { type ReactNode } from "react";
import { type z } from "zod";
import { type FileSlugTitles } from "~/static/fileTypes";
import {
  type investmentSchema,
  type schemaAddCoCustomer,
  type schemaAdditionalIncomeForm,
  type schemaAssetForm,
  type schemaAssetFormCreate,
  type schemaBalanceSheetCivilStateForm,
  type schemaBalanceSheetCompanyFinancialCapacityForm,
  type schemaBalanceSheetIncomesForm,
  type schemaBalanceSheetProjectForm,
  type schemaCustomerJobType,
  type schemaInvestmentForm,
  type schemaModifyAccountInfoForm,
  type schemaModifyPasswordForm,
  type schemaRejectedSimulationDialog,
  type schemaRepeatableFileList,
  type schemaRepeatableFilePath,
  type schemaSimpleFileList,
  type schemaSimpleFilePath,
} from "./schemas";

// BALANCE SHEET //

export enum BalanceSheetFieldName {
  description = "description",
  requestedAmount = "requestedAmount",
  // user
  userLastname = "user.lastname",
  userFirstname = "user.firstname",
  userEmail = "user.email",
  userPhone = "user.phone",
  // customer
  customerAge = "customer.age",
  customerMaritalStatus = "customer.maritalStatus",
  customerMarriagetype = "customer.marriagetype",
  customerChildrenSupportedCount = "customer.childrenSupportedCount",
  customerEmployeeType = "customer.employeeType",
  customerEmployeeIsTrialing = "customer.employeeIsTrialing",
  customerJobType = "customer.jobType",
  customerSelfEmployedHasEnoughReviews = "customer.hasEnoughAnnualReviews",
  customerJobIncomeNet = "customer.jobIncomeNet",
  customerRentIncomes = "customer.rentIncomes",
  customerAdditionalIncomes = "customer.additionalIncomes",
  customerConsumerLoanCharges = "customer.customerConsumerLoanCharges",
  customerRealEstateLoanCharges = "customer.realEstateLoanCharges",
  customerAdditionalCharges = "customer.customerAdditionalCharges",
  customerRentCharges = "customer.customerRentCharges",
  customerInvestments = "customer.customerInvestments",
  // coCustomer
  coCustomerAge = "coCustomer.age",
  coCustomerMaritalStatus = "coCustomer.maritalStatus",
  coCustomerMarriagetype = "coCustomer.marriagetype",
  coCustomerChildrenSupportedCount = "coCustomer.childrenSupportedCount",
  coCustomerEmployeeType = "coCustomer.employeeType",
  coCustomerEmployeeIsTrialing = "coCustomer.employeeIsTrialing",
  coCustomerJobType = "coCustomer.jobType",
  coCustomerSelfEmployedHasEnoughReviews = "coCustomer.hasEnoughAnnualReviews",
  coCustomerJobIncomeNet = "coCustomer.jobIncomeNet",
  coCustomerRentIncomes = "coCustomer.rentIncomes",
  coCustomerAdditionalIncomes = "coCustomer.additionalIncomes",
  coCustomerConsumerLoanCharges = "coCustomer.customerConsumerLoanCharges",
  coCustomerRealEstateLoanCharges = "coCustomer.realEstateLoanCharges",
  coCustomerAdditionalCharges = "coCustomer.customerAdditionalCharges",
  coCustomerRentCharges = "coCustomer.customerRentCharges",
  coCustomerInvestments = "coCustomer.customerInvestments",
}

export type balanceSheetProjectForm = z.infer<
  typeof schemaBalanceSheetProjectForm
>;

export type SchemaBalanceSheetCivilStateForm = z.infer<
  typeof schemaBalanceSheetCivilStateForm
>;

export type SchemaBalanceSheetIncomesForm = z.infer<
  typeof schemaBalanceSheetIncomesForm
>;

export type GenericDialogMode =
  | "create"
  | "update"
  | "ghost"
  | "seeMore"
  | "confirmation";

export type schemaAssetFormCreate = z.infer<typeof schemaAssetFormCreate>;

export type SchemaAssetForm = z.infer<typeof schemaAssetForm>;

// ADD INVESTEMENT FORM //
export type InvestmentField = z.infer<typeof investmentSchema>;

export type SchemaInvestmentForm = z.infer<typeof schemaInvestmentForm>;

// ADD CO CUSTOMER FORM //
export type SchemaAddCoCustomer = z.infer<typeof schemaAddCoCustomer>;

// ADD INCOME FORM //
export type SchemaAdditionalIncomeForm = z.infer<
  typeof schemaAdditionalIncomeForm
>;

// COMPANY FINANCIAL CAPACITY //
export type SchemaBalanceSheetCompanyFinancialCapacityForm = z.infer<
  typeof schemaBalanceSheetCompanyFinancialCapacityForm
>;

// FILES //

export type SimpleFilePath = z.infer<typeof schemaSimpleFilePath>;

export type RepeatableFilePath = z.infer<typeof schemaRepeatableFilePath>;

export type SimpleFileList = z.infer<typeof schemaSimpleFileList>;

export type RepeatableFileList = z.infer<typeof schemaRepeatableFileList>;

export type FileListFormatted = Partial<Record<FileSlugTitles, SimpleFileList>>;

// MODIFY PASSWORD FORM //
export type SchemaModifyPasswordForm = z.infer<typeof schemaModifyPasswordForm>;

// MODIFY ACCOUNT INFO //
export type SchemaModifyAccountInfoForm = z.infer<
  typeof schemaModifyAccountInfoForm
>;
// My Project Page
export enum ValueUnit {
  percent = "percent",
  currency = "currency",
}

export type SituationLine = {
  icon: JSX.Element;
  label: string;
  value: number | undefined;
  valueUnit: ValueUnit;
};

export enum SectionId {
  resume = "resume",
  project = "project",
  civil = "civil",
  incomes = "incomes",
  charges = "charges",
  finances = "finances",
  assets = "assets",
  // company
  company = "company",
  financialCapacity = "financialCapacity",
}

export interface BalanceSheetSection {
  id: SectionId;
  title: string;
  subtitle: string;
  hasSuggestionBadge?: boolean;
  component: ReactNode;
  editForm?: (props: { closeModal: () => void }) => ReactNode;
  totalValue?: number;
}

/// Balance Sheet Company Financial Capacity
interface FinancialValuesBase {
  year: number;
  revenue: number;
  balanceSheetSize: number;
  exploitationResult: number;
  equity: number;
  liquidity: number;
  charges: number;
  loanRepayment: number;
  remainingLoanAmount: number;
  netProfit: number;
  loanRepayementCapacity: number;
  accountValue: number;
  mortgageLineAmount: number;
}

interface FinancialValuesIR extends FinancialValuesBase {
  taxType: typeof TaxType.ir;
  loanInterest: number;
}

interface FinancialValuesIS extends FinancialValuesBase {
  taxType: typeof TaxType.is;
  depreciationCharge: number;
}

export type BalanceSheetFinancialCapacity = (
  | FinancialValuesIS
  | FinancialValuesIR
)[];

export type BalanceSheetCustomerJobType = z.infer<typeof schemaCustomerJobType>;

//// Financing Plan Details

export interface FinancingPlanIdParam extends Params {
  financingPlanId: string;
}
export interface FinancingPlanDetailsProps {
  params: FinancingPlanIdParam;
}

// Rejected Simulation Dialog

export type SchemaRejectedSimulationDialog = z.infer<
  typeof schemaRejectedSimulationDialog
>;
